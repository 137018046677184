/* eslint-disable no-console */

export const browserLogger = () => {
  return {
    debug: (message: string, meta?: Record<string, unknown>) => console.debug(message, meta),
    info: (message: string, meta?: Record<string, unknown>) => console.info(message, meta),
    warn: (message: string, meta?: Record<string, unknown>) => console.warn(message, meta),
    error: (message: string, meta?: Record<string, unknown>) => console.error(message, meta)
  }
}
